.FaqMainContainer{
    background: #FCFCFC;
    padding: 40px;
}

.FaqMainContainer .FaqInnerContainer{
    max-width: 730px;
    margin: 0 auto;
    text-align: center;
}

.FaqMainContainer .FaqInnerContainer .LogoContainer{
    height: 50px;
    cursor: pointer;
}

.FaqMainContainer .FaqInnerContainer .FAQLogo{
    object-fit: contain;
    height: 50px;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiIconButton-edgeEnd{
    margin-right:0;
    position:absolute;
    left: 0;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiExpansionPanelSummary-content{
    margin:12px 30px;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiSvgIcon-root{
    color:#56B149;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .Mui-expanded .addIcon{
    display:none;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .minusIcon{
    display:none;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .Mui-expanded .minusIcon{
    display:block;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .accTitle{
    color:#0B132B;
    font-size:16px;
    font-weight: 500;
    font-family: 'Poppins';
    letter-spacing: 0;
    text-align:left !important;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .accDate {
    text-align: left;
    color: #676C7B !important;
    font-size: 14px !important;
    font-weight: 400;
    font-family: 'Poppins';
    letter-spacing: 0;
    line-height: 20px;
    width: 100% !important;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiExpansionPanelDetails-root{
    padding: 8px 46px 15px !important;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer  .MuiExpansionPanelSummary-root.Mui-expanded{
    min-height:48px;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .IconContianer{
    display:inherit;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiButtonBase-root{
    display:flex !important;
    padding:0 16px;
}