.ExpansionFooterContainer {
    background: #ffffff;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: #e5e5e5 1px solid;
}

.ExpansionContainer {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
}

.ExpansionFooterContainer .FooterTop .tickIcon{
    margin-left: 8px;
}

.ExpansionFooterContainer .FooterTop h1{
    font-size: 20px;
    color: #444444;
    font-weight: 600;
    margin-top: 0;
}

.ExpansionFooterContainer .FooterTop p{
    font-size: 14px;
    color: #444444;
    font-weight: 400;
    letter-spacing: 0;
    margin:0;
    display: flex;
    align-items: center;
}

.ExpansionFooterContainer .FooterTop p svg {
    font-size: 17px;
    margin-right: 8px;
    color: #7F91A2;
}

.ExpansionFooterContainer .FooterTosSection {
    border-top: #e5e8ec 1px solid;
    margin-top: 30px;
    padding: 14px 0;
}

.ExpansionFooterContainer .FooterTosSection .FooterTosSectionInner{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.ExpansionFooterContainer .FooterTosSection a{
    color:#7F91A2 ;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
}

.ExpansionFooterContainer .FooterTosSection span{
    color:#7F91A2 ;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 15px;
}

.ExpansionFooterContainer .FooterTosSection .CraftedText{
    color:#7F91A2 ;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
}

