
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    font-family: 'Poppins', sans-serif;
}

.HeaderOneMainContainer .HeaderOneContainer .HeaderOnesiteLogo a{
    max-width: initial !important;
    height: 56px;
}

.PageTitleOneMainContainer .PageTitleOneTextContainer .PageSubTitleOne {
    font-size: 26px !important;
}

.PageTitleOneMainContainer .PageTitleOneTextContainer .PageTitleOneMain {
    text-transform: initial !important;
}

.formTitle{
    font-family: 'Poppins', sans-serif;
    color: #0B132B;
    font-weight: 700;
    font-size: 24px;
}

.formSubTitle{
    font-family: 'Poppins', sans-serif !important;
    color: #0B132B !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    text-align: left !important;
}

.packageCardContainer .DayText {
    font-size: 14px;
    color: #0b132b;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    text-align: center;
}

.packageCardContainer .MuiCardContent-root {
    padding: 16px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.FromList{
    margin-left: 15px;
} 

.FromList ul{
    margin: 0;
    padding: 0;
}
.FromList ul li {
    font-size: 16px;
    color: #0b132b;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 12px;
    display: block;
}

.FromList ul li::before {
    content: "\2022";
    color: #56B149;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }

  .InfoText{
	background: #fff;
}

.InfoText li {
    display: flex;
    align-items: flex-start;
}

.InfoText p{
	font-family: 'Poppins', sans-serif !important;
	color: #676C7B;
	font-size: 12px;
	font-weight: 400;
    margin: 0 !important;
	
}

.InfoText p span{
	font-weight:600;
}

.InfoText li svg{
	color:#E4E8EC;
	margin-right: 12px;
	position:relative;
	top: 3px;
}

.center{
    text-align: center !important;
}

.packageCardContainer .MuiCardContent-root section svg{
    color: #56B149 !important;
}

.DetailSection p{
    font-family: 'Poppins', sans-serif !important;
	color: #0B132B;
	font-size: 16px;
	font-weight: 400;
    margin: 0 !important;
    margin-bottom: 12px !important;
}

.DetailSectionTlt{
    display: flex;
}

.DetailSectionTlt p{
    color: #676C7B;
    font-size: 14;
}

.DetailSectionTlt a{
    color: #56B149;
    font-size: 14;
    font-weight: 500;
    margin-left: 12px;
}

.MembershipCard .MuiTabs-flexContainer {
    display: flex;
    width: 100%;
    margin: 0 -5px;
}

.MembershipCard .MuiTabs-indicator{
    display: none;
}

.MembershipCard .MuiTab-root{
    padding: 16px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0 10px;
    flex: 1;
    border-radius: 4px;
    position: relative;
}

.MembershipCard .MuiTab-root .MuiTab-wrapper h1{
    font-size: 14px;
    color: #0b132b;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    text-transform: initial !important;
    margin: 0;
}

.MembershipCard .MuiTab-root .MuiTab-wrapper p{
    font-size: 14px;
    color: #676C7B;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    text-transform: initial !important;
    margin: 0;
}

.MembershipCard .MuiTab-root .MuiTab-wrapper svg{
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
    color: #56B149;
}

.MembershipCard .MuiTab-root.Mui-selected{
    border: #56B149 2px solid;
}

.MembershipCard .MuiTab-root.Mui-selected svg{
    display: block;
}

.StapperMainContainer .FormButtonBody .buttonBack {
    color: #56b149 !important;

}